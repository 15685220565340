import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import 'vuetify/dist/vuetify.min.css';
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'


import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
})

createApp(App)
    .use(router)
    .use(vuetify)
    .mount('#app')
