<template>
  <div id="app">
    <header>
      <!-- Глобальный компонент шапки сайта -->
    </header>

    <router-view></router-view>

    <footer>
      <!-- Глобальный компонент подвала сайта -->
    </footer>
  </div>
</template>

<script>
export default {
  // ... ваш код скрипта ...
}
</script>

<style>
/* Стили для общего макета */
</style>