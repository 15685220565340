import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Войти в систему',
      component: () => import('../views/LoginForm.vue'),
      meta: {
        title: 'Войти в систему'
      }
    },
    {
      path: '/sitemap',
      name: 'Постороение карты сайта',
      component: () => import('../views/SiteMap.vue'),
      meta: {
        title: 'Постороение карты сайта'
      }
    },
    {
      path: '/registration',
      name: 'Регистрация в системе',
      component: () => import('../views/RegistrationForm.vue'),
      meta: {
        title: 'Регистрация в системе'
      }
    },
    {
      path: '/admin',
      name: 'Система администрирования',
      component: () => import('../views/AdministratorAdmin.vue'),
      meta: {
        title: 'Система администрирования'
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/sitemap'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Обновляем заголовок страницы при каждом переходе
  document.title = to.meta.title || 'Моя приложение'
  next()
})

export default router